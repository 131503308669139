import React from "react"
import { graphql } from "gatsby"
import { ListsStyle, PageGrid } from "../styles/Grids"

export default function CodingPage({ data }) {
  const codingInfo = data.allMarkdownRemark.nodes[0].frontmatter
  const codingSkills = codingInfo.skills
  return (
    <PageGrid>
      <h1 className="center">{codingInfo.title}</h1>
      <div className="center">
        <img src={codingInfo.featuredImage.publicURL} alt="nerdy coder" />
      </div>
      <p style={{ margin: "0" }}>{codingInfo.intro}</p>
      <h2 className="center">Tech Savviness:</h2>
      <ListsStyle>
        <ul>
          {codingSkills.map((skill, i) => (
            <span key={skill + i} className="mark tilt center">
              {`<${skill} />`}
            </span>
          ))}
        </ul>
      </ListsStyle>
    </PageGrid>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "coding" } } }
    ) {
      nodes {
        frontmatter {
          title
          intro
          skills
          templateKey
          featuredImage {
            publicURL
            id
          }
        }
      }
    }
  }
`
